
export enum BondReasonCd {
	BROKER_NEEDS_CLIENT_AUTHENTICATION = 'broker_needs_client_authentication',
	BROKER_REJECTED = 'broker_rejected',
	MISCELLANEOUS = 'miscellaneous',
	MISSING_CUSTOMS_PPWK = 'missing_customs_ppwk',
	MOVR_PRO = 'movr_pro',
	NO_BROKER_INDICATED = 'no_broker_indicated',
	NO_ORIGIN_COUNTRY = 'no_origin_country',
	OVERAGE = 'overage',
	RCVD_PPWK_NOT_SET_UP = 'rcvd_ppwk_not_set_up',
	VALUE_NOT_INDICATED = 'value_not_indicated',
	VIASAFE_OPERATOR_ERR = 'viasafe_operator_err'}

