/**
 * UserPreference API
 * Version: 1.0
 * Build: 1.0.0.18
 */

import {
	ListInfo,
	AuditInfo,
	ActionCd
} from '@xpo-ltl/sdk-common';

/**
 * Request for deleteUserPreferenceById
 */
export class DeleteUserPreferenceByIdPath {
	/**
	 * Id of userPreference instance.
	 * Required: false
	 * Array: false
	 */
	userPrefId:number = undefined;
}
/**
 * Query parameters used to retrieve a user's preferences.
 */
export class DeleteUserPreferencesByAppNamePath {
	/**
	 * The name of the application.
	 * Required: true
	 * Array: false
	 */
	applicationName:string = undefined;
}
/**
 * Query parameters used to retrieve a user's preferences.
 */
export class DeleteUserPreferencesByAppNameQuery {
	/**
	 * The name of the UI Component.
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * The ID of the user that is associated with the preferences.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Query parameters used to retrieve a user's preferences.
 */
export class GetUserPreferenceQuery {
	/**
	 * Id of user for whom the preferences are to be stored.
	 * 					By default, the id is determined by the API gateway based on subscription.
	 * 					The Id passed here will only be used when there is a generic subscription
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Name of the UI Component.
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * If true, retrieve the UI component default preferences
	 * Required: false
	 * Array: false
	 */
	uiComponentDefault:boolean = undefined;
	/**
	 * If true, retrieve the application default preferences
	 * Required: false
	 * Array: false
	 */
	appDefault:boolean = undefined;
	/**
	 * If true, retrieve the global default preferences
	 * Required: false
	 * Array: false
	 */
	globalDefault:boolean = undefined;
}
/**
 * Response for getUserPreference
 */
export class GetUserPreferenceResp {
	/**
	 * The preferences in JSON format
	 * Required: false
	 * Array: false
	 */
	preferences:string = undefined;
}
/**
 * Request for getUserPreferenceById
 */
export class GetUserPreferenceByIdPath {
	/**
	 * Id of userPreference instance.
	 * Required: true
	 * Array: false
	 */
	userPrefId:number = undefined;
}
/**
 * Response for getUserPreferenceById
 */
export class GetUserPreferenceByIdResp {
	/**
	 * The user preference details.
	 * Required: false
	 * Array: false
	 */
	userPreference:UserPreference = undefined;
}
/**
 * Query parameter used to upsert a user preference.
 */
export class UpsertUserPreferenceQuery {
	/**
	 * Id of user for whom the preferences are to be stored.
	 * 					By default, the id is determined by the API gateway based on subscription.
	 * 					The Id passed here will only be used when there is a generic subscription
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Request for upsertUserPreference
 */
export class UpsertUserPreferenceRqst {
	/**
	 * Name of the UI Component. If omitted, upsert the default preferences for the UI component
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * If true, upsert the UI component default preferences
	 * Required: false
	 * Array: false
	 */
	uiComponentDefault:boolean = undefined;
	/**
	 * If true, upsert the application default preferences
	 * Required: false
	 * Array: false
	 */
	appDefault:boolean = undefined;
	/**
	 * If true, upsert the global default preferences
	 * Required: false
	 * Array: false
	 */
	globalDefault:boolean = undefined;
	/**
	 * The preferences in JSON format
	 * Required: false
	 * Array: false
	 */
	preferences:string = undefined;
	/**
	 * Id of user for whom the preferences are to be stored.
	 * 					By default, the id is determined by the API gateway based on subscription.
	 * 					The Id passed here will only be used when there is a generic subscription
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Request for createPreferenceGroupingUser
 */
export class CreatePreferenceGroupingUserRqst {
	/**
	 * unique identifier of Preference Grouping
	 * Required: true
	 * Array: false
	 */
	groupingInstId:number = undefined;
	/**
	 * User identifier
	 * Required: true
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Request for deletePreferenceGrouping
 */
export class DeletePreferenceGroupingPath {
	/**
	 * Id of preference grouping.
	 * Required: true
	 * Array: false
	 */
	groupingInstId:number = undefined;
}
/**
 * Request for deletePreferenceGroupingUser
 */
export class DeletePreferenceGroupingUserPath {
	/**
	 * unique identifier of Preference Grouping
	 * Required: true
	 * Array: false
	 */
	groupingInstId:number = undefined;
	/**
	 * User identifier
	 * Required: true
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Request for getPreferenceGrouping
 */
export class GetPreferenceGroupingPath {
	/**
	 * Id of preference grouping.
	 * Required: true
	 * Array: false
	 */
	groupingInstId:number = undefined;
}
/**
 * Response for getPreferenceGrouping
 */
export class GetPreferenceGroupingResp {
	/**
	 * Preference grouping applicable to the UI component/application (optional).
	 * Required: false
	 * Array: false
	 */
	preferenceGrouping:PreferenceGrouping = undefined;
}
/**
 * Response for listAllPreferenceGroupings
 */
export class ListAllPreferenceGroupingsQuery {
	/**
	 * Name of the UI Component.
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * The optional identifier of the user for which perference groupings will be returned.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listAllPreferenceGroupings
 */
export class ListAllPreferenceGroupingsResp {
	/**
	 * The preference grouping instances.
	 * Required: false
	 * Array: true
	 */
	preferenceGroupings:PreferenceGrouping[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for listPreferenceGroupingsForUser
 */
export class ListPreferenceGroupingsForUserPath {
	/**
	 * Id of the user.
	 * Required: true
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Request for listPreferenceGroupingsForUser
 */
export class ListPreferenceGroupingsForUserQuery {
	/**
	 * Name of the UI Component.
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for UpdateUserPreferencesForPreferenceGrouping
 */
export class ListPreferenceGroupingsForUserResp {
	/**
	 * The preference grouping instances.
	 * Required: false
	 * Array: true
	 */
	preferenceGroupings:PreferenceGrouping[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listUsersForPreferenceGrouping
 */
export class ListUsersForPreferenceGroupingPath {
	/**
	 * Id of preference grouping.
	 * Required: true
	 * Array: false
	 */
	groupingInstId:number = undefined;
}
/**
 * Response for listUsersForPreferenceGrouping
 */
export class ListUsersForPreferenceGroupingQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listUsersForPreferenceGrouping
 */
export class ListUsersForPreferenceGroupingResp {
	/**
	 * Users attached to a preference grouping.
	 * Required: false
	 * Array: true
	 */
	preferenceGroupingUsers:InterfaceEmployee[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listUserPreferencesForPreferenceGrouping
 */
export class ListUserPreferencesForPreferenceGroupingPath {
	/**
	 * Id of customer grouping.
	 * Required: true
	 * Array: false
	 */
	groupingInstId:number = undefined;
}
/**
 * Response for listUserPreferencesForPreferenceGrouping
 */
export class ListUserPreferencesForPreferenceGroupingQuery {
	/**
	 * Id of the Application.
	 * Required: false
	 * Array: false
	 */
	appInstId:number = undefined;
	/**
	 * Name of the UI Component.
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listUserPreferencesForPreferenceGrouping
 */
export class ListUserPreferencesForPreferenceGroupingResp {
	/**
	 * The list of user preference details.
	 * Required: false
	 * Array: true
	 */
	userPreferences:UserPreference[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for upsertPreferenceGrouping
 */
export class UpsertPreferenceGroupingRqst {
	/**
	 * The name of the UI Component for which the Preference Grouping is being upserted.
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * The preference grouping instance.
	 * Required: true
	 * Array: false
	 */
	preferenceGrouping:PreferenceGrouping = undefined;
}
/**
 * Response for createPreferenceGrouping
 */
export class UpsertPreferenceGroupingResp {
	/**
	 * The preference grouping that was upserted.
	 * Required: false
	 * Array: false
	 */
	preferenceGrouping:PreferenceGrouping = undefined;
}
/**
 * Request for upsertUserPreferenceForPreferenceGrouping
 */
export class UpsertUserPreferenceForPreferenceGroupingRqst {
	/**
	 * The name of the UI Component for which the Preference Grouping is being created.
	 * Required: true
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * The unique identifier of the preference group to be attached to the user preference.
	 * Required: true
	 * Array: false
	 */
	groupingInstId:number = undefined;
	/**
	 * The user preference grouping object to be upserted.
	 * Required: true
	 * Array: false
	 */
	userPreference:UserPreference = undefined;
}
/**
 * Response for upsertUserPreferenceForPreferenceGrouping
 */
export class UpsertUserPreferenceForPreferenceGroupingResp {
	/**
	 * The user preference that was upserted.
	 * Required: false
	 * Array: false
	 */
	userPreference:UserPreference = undefined;
}
/**
 * Path parameter used to delete a preference grouping tool.
 */
export class DeletePreferenceGroupingToolPath {
	/**
	 * The unique identifier of the Preference Grouping.
	 * Required: true
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * The sequence number associated with the Preference Grouping Tool.
	 * Required: true
	 * Array: false
	 */
	toolPreferenceSequenceNbr:number = undefined;
}
/**
 * Path parameters used to get a preference grouping tool.
 */
export class GetPreferenceGroupingToolPath {
	/**
	 * The unique identifier of the Preference Grouping.
	 * Required: true
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * The sequence number associated with the Preference Grouping Tool.
	 * Required: true
	 * Array: false
	 */
	toolPreferenceSequenceNbr:number = undefined;
}
/**
 * Response to the request to get a preference grouping tool.
 */
export class GetPreferenceGroupingToolResp {
	/**
	 * The preference grouping tool that matches the input provided.
	 * Required: true
	 * Array: false
	 */
	preferenceGroupingTool:ToolPreference = undefined;
}
/**
 * Path parameter used to list preference grouping tools.
 */
export class ListPreferenceGroupingToolsPath {
	/**
	 * The name of the Preference Grouping.
	 * Required: true
	 * Array: false
	 */
	groupingName:string = undefined;
}
/**
 * Query parameters that are used to filter the list of preference grouping tools.
 */
export class ListPreferenceGroupingToolsQuery {
	/**
	 * The name of the UI Component for which the preference grouping tools will be retrieved.
	 * Required: true
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * The first generic filter key value to identify the tool preference.
	 * Required: true
	 * Array: false
	 */
	filterKey1:string = undefined;
	/**
	 * The second generic filter key value to identify the tool preference.
	 * Required: true
	 * Array: false
	 */
	filterKey2:string = undefined;
	/**
	 * The third generic filter key value to identify the tool preference.
	 * Required: true
	 * Array: false
	 */
	filterKey3:string = undefined;
	/**
	 * The fourth generic filter key value to identify the tool preference.
	 * Required: true
	 * Array: false
	 */
	filterKey4:string = undefined;
	/**
	 * The fifth generic filter key value to identify the tool preference.
	 * Required: true
	 * Array: false
	 */
	filterKey5:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: true
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list preference grouping tools that match the input criteria.
 */
export class ListPreferenceGroupingToolsResp {
	/**
	 * The preference grouping tools that matches the input provided.
	 * Required: false
	 * Array: true
	 */
	preferenceGroupingTools:ToolPreference[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: true
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to upsert a preference grouping tool.
 */
export class UpsertPreferenceGroupingToolRqst {
	/**
	 * The preference grouping tool that will update an existing entry or create a new one.
	 * Required: true
	 * Array: false
	 */
	preferenceGroupingTool:ToolPreference = undefined;
}
/**
 * Response to the request to upsert a preference grouping tool.
 */
export class UpsertPreferenceGroupingToolResp {
	/**
	 * The preference grouping tool that will update an existing entry or create a new one.
	 * Required: true
	 * Array: false
	 */
	preferenceGroupingTool:ToolPreference = undefined;
}
/**
 * Stores default preferences for applications
 */
export class ApplicationPreference {
	/**
	 * unique identifier of the UPF_APPLICATION table. this key is generated from a sequence.
	 * Required: false
	 * Array: false
	 */
	applicationInstId:number = undefined;
	/**
	 * name of the application. The application name will be decided and created on WSO2 gateway during the API subscription process.
	 * Required: false
	 * Array: false
	 */
	applicationName:string = undefined;
	/**
	 * Default preferences for the application.
	 * Required: false
	 * Array: false
	 */
	applicationPreference:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transaction.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * Stores one or more preference groupings for a 
 * 
 * UI component.  A preference grouping is a collection of one or more user preferences.  
 * 
 * Added 09/10/2019
 */
export class PreferenceGrouping {
	/**
	 * Identifier of the preference 
	 * 
	 * grouping.  This key is generated from a sequence.
	 * Required: false
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * The logical name of the 
	 * 
	 * preference grouping.
	 * Required: false
	 * Array: false
	 */
	groupingName:string = undefined;
	/**
	 * The data for the 
	 * 
	 * preference grouping in JSON format.
	 * Required: false
	 * Array: false
	 */
	groupingPreference:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The list of user IDs assigned to the preference grouping.
	 * Required: false
	 * Array: true
	 */
	assigneeEmployeeIds:string[] = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transaction.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * Stores the userids attached to a 
 * 
 * preference grouping for a UI component.  Added 09/10/2019
 */
export class PreferenceGroupingUser {
	/**
	 * Identifier of the preference 
	 * 
	 * grouping.  This key is generated from a sequence.
	 * Required: false
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * The user id of the person 
	 * 
	 * attached to the preference grouping
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transaction.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * Stores one or more tools that are to be executed for the grouping preference, for example a macro or a script.  Added 01/27/2020
 */
export class ToolPreference {
	/**
	 * Identifier of the preference grouping.  This key is generated from a sequence.
	 * Required: false
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * Part of identifier of the tool preference for a grouping preference.
	 * Required: false
	 * Array: false
	 */
	toolPreferenceSequenceNbr:number = undefined;
	/**
	 * The logical name of the preference grouping.
	 * Required: false
	 * Array: false
	 */
	toolName:string = undefined;
	/**
	 * The data for the preference grouping in JSON format.
	 * Required: false
	 * Array: false
	 */
	toolPreference:string = undefined;
	/**
	 * Generic filter key value to identify the tool preference.
	 * Required: false
	 * Array: false
	 */
	filterKey1:string = undefined;
	/**
	 * Generic filter key value to identify the tool preference.
	 * Required: false
	 * Array: false
	 */
	filterKey2:string = undefined;
	/**
	 * Generic filter key value to identify the tool preference.
	 * Required: false
	 * Array: false
	 */
	filterKey3:string = undefined;
	/**
	 * Generic filter key value to identify the tool preference.
	 * Required: false
	 * Array: false
	 */
	filterKey4:string = undefined;
	/**
	 * Generic filter key value to identify the tool preference.
	 * Required: false
	 * Array: false
	 */
	filterKey5:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transaction.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * Stores default preferences for ui component
 */
export class UiComponentPreference {
	/**
	 * unique identifier of the UI_CMPNT_INST_ID table. this key is generated from a sequence.
	 * Required: false
	 * Array: false
	 */
	uiComponentInstId:number = undefined;
	/**
	 * name of the UI component.
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * Default preferences for the UI component. Overrides or augments application default preferences
	 * Required: false
	 * Array: false
	 */
	uiComponentPreference:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transaction.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * Stores preferences for user
 */
export class UserPreference {
	/**
	 * ID of the user for whom the PREFERENCES apply.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * PREFERENCES for the user applicable to the UI component/application. Overrides or augments application/UI Component default PREFERENCES
	 * Required: false
	 * Array: false
	 */
	userPreference:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The logical name of the user preference. Added 09/10/2019
	 * Required: false
	 * Array: false
	 */
	userPreferenceName:string = undefined;
	/**
	 * Generated unique identifier (instead of USER_ID + APP_INST_ID + UI_CMPNT_INST_ID) added 09/11/2019
	 * Required: false
	 * Array: false
	 */
	userPrefId:number = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transaction.  In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * Details about employees that process and approve claims.
 */
export class InterfaceEmployee {
	/**
	 * Employee Id of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * First Name of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Middle Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	middleName:string = undefined;
	/**
	 * Last Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Job Description of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * The domicile SIC location for the employee.
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * The employee id of this employee's supervisor.
	 * Required: false
	 * Array: false
	 */
	supervisorEmployeeId:string = undefined;
	/**
	 * Desc: The business phone number of the employee.  It is sourced from HRS_EMPL_PHONE_VW in DB2 for typ_cd = 'B'.  01/10/2019 Added by Cathy
	 * Required: false
	 * Array: false
	 */
	businessPhoneTxt:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}



