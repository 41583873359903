
export enum NextBusinessDayOperationCd {
	EAM_DAY = 'EamDay',
	HOLIDAY = 'Holiday',
	LH_DAY = 'LHDay',
	NEXT_BUSINESS_DAY = 'NextBusinessDay',
	NON_WK_DY = 'NonWkDy',
	PN_D_DAY = 'PnDDay',
	REVENUE = 'Revenue',
	SVC_DAY = 'SvcDay'}

