
export enum ShipmentAuditStatusCd {
	CONFLICT = 'Conflict',
	DR_CORRECTION_DONE = 'DRCorrectionDone',
	DR_CORRECTION_DONE_OTHER = 'DRCorrectionDoneOther',
	DR_CORRECTION_FAILED = 'DRCorrectionFailed',
	DR_CORRECTION_READY = 'DRCorrectionReady',
	EXPIRED = 'Expired',
	IGNORED = 'Ignored',
	PENDING = 'Pending'}

