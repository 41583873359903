
export enum WorkerStatusCd {
	ASSIGNED = 'Assigned',
	AVAILABLE = 'Available',
	RETURNING = 'Returning',
	SICK = 'Sick',
	TRAINING = 'Training',
	UNAVAILABLE = 'Unavailable',
	VACATION = 'Vacation'}

