
export enum ScoEventSubTypeCd {
	BREAK = 'Break',
	DELIVERY = 'Delivery',
	DRIVER = 'Driver',
	DROP = 'Drop',
	EXCLUDE_TRAILER = 'ExcludeTrailer',
	FORCED = 'Forced',
	GATE = 'Gate',
	HOOK = 'Hook',
	HOOK_EMPTY = 'HookEmpty',
	HOOK_LOADED = 'HookLoaded',
	INVALID = 'Invalid',
	MSG_FROM_DSR = 'MsgFromDsr',
	MSG_TO_DSR = 'MsgToDsr',
	NORMAL_PICKUP = 'NormalPickup',
	OTHER = 'Other',
	PLAN_TRLR_ACTN = 'PlanTrlrActn',
	REUSE_TIMEOUT = 'ReuseTimeout',
	ROUTE = 'Route',
	ROUTE_SHIPMENT = 'RouteShipment',
	SPOT_EMPTY = 'SpotEmpty',
	SPOT_LOADED = 'SpotLoaded',
	STOP = 'Stop',
	TRIP = 'Trip',
	TRLR_CON = 'TrlrCon',
	VALID = 'Valid'}

