
export enum StopDetailCd {
	ACTIVITIES = 'Activities',
	CUSTOMERS = 'Customers',
	ROUTE = 'Route',
	SHIPMENTS = 'Shipments',
	SPECIAL_SERVICES = 'SpecialServices',
	STOP_WINDOWS = 'StopWindows',
	TRIP_NODES = 'TripNodes'}

