
export enum CustomerRequestQueueNameCd {
	CREDIT = 'Credit',
	LOCAL = 'Local',
	MEXICO = 'Mexico',
	NAQQ = 'NAQQ',
	NATIONAL = 'National',
	OTHERS = 'Others',
	PRIORITY = 'Priority',
	SPECIAL = 'Special'}

