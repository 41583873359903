import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	DeleteUserPreferenceByIdPath,
	DeleteUserPreferencesByAppNameQuery,
	DeleteUserPreferencesByAppNamePath,
	GetUserPreferenceResp,
	GetUserPreferenceQuery,
	GetUserPreferenceByIdResp,
	GetUserPreferenceByIdPath,
	UpsertUserPreferenceRqst,
	UpsertUserPreferenceQuery,
	DeletePreferenceGroupingPath,
	GetPreferenceGroupingResp,
	GetPreferenceGroupingPath,
	ListAllPreferenceGroupingsResp,
	ListAllPreferenceGroupingsQuery,
	ListPreferenceGroupingsForUserResp,
	ListPreferenceGroupingsForUserQuery,
	ListPreferenceGroupingsForUserPath,
	ListUserPreferencesForPreferenceGroupingResp,
	ListUserPreferencesForPreferenceGroupingQuery,
	ListUserPreferencesForPreferenceGroupingPath,
	ListUsersForPreferenceGroupingResp,
	ListUsersForPreferenceGroupingQuery,
	ListUsersForPreferenceGroupingPath,
	UpsertPreferenceGroupingRqst,
	UpsertPreferenceGroupingResp,
	UpsertUserPreferenceForPreferenceGroupingRqst,
	UpsertUserPreferenceForPreferenceGroupingResp,
	DeletePreferenceGroupingToolPath,
	GetPreferenceGroupingToolResp,
	GetPreferenceGroupingToolPath,
	ListPreferenceGroupingToolsResp,
	ListPreferenceGroupingToolsQuery,
	ListPreferenceGroupingToolsPath,
	UpsertPreferenceGroupingToolRqst,
	UpsertPreferenceGroupingToolResp,
	CreatePreferenceGroupingUserRqst,
	DeletePreferenceGroupingUserPath
} from './api-userpreference';

import {
	GetHealthCheckResp,
	Envelope,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class UserPreferenceApiService extends BaseService {
	private static UserPreferenceApiEndpoint = 'userpreferenceApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetHealthCheckResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<User>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Deletes user preference by ID.
	* <br/>
	* <br/>Primary Parameter:
	* <br/>- userPrefId. The user preference instance ID for which to delete the preferences.        
	*/
	public deleteUserPreferenceById(
							   pathParams: DeleteUserPreferenceByIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preferences/{userPrefId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation deletes a user preference based on the application name provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid appName must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input provided is valid, then the user preference associated with it is deleted.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteUserPreferencesByAppName(
							   pathParams: DeleteUserPreferencesByAppNamePath,
							   queryParams: DeleteUserPreferencesByAppNameQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/apps/{applicationName}/user-preferences'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Returns user preferences. The user id and application name are derived from the API subscription and passed in the transaction context.
	* <br/>
	* <br/>Optional Parameters:
	* <br/>- uiComponentName. The UI component for which to retrieve the preferences
	* <br/>- uiComponentDefault. A boolean indicating whether to retrieve the default preferences for the UI component.
	* <br/>- appDefault. A boolean indicating whether to retrieve the default preferences for the application. Cannot be combined with the UI Component options
	* <br/>- globalDefault. A boolean indicating whether to retrieve the global preferences. Cannot be combined with either app default or UI Component options.
	* <br/>        
	*/
	public getUserPreference(
							   queryParams: GetUserPreferenceQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetUserPreferenceResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/users/preferences'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get user preference by identifier.
	* <br/>        Primary Parameter:
	* <br/>        	- userPrefId. The user preference instance id for which to retrieve the preferences.
	* <br/>        
	*/
	public getUserPreferenceById(
							   pathParams: GetUserPreferenceByIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetUserPreferenceByIdResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preferences/{userPrefId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Inserts or Updates user preferences. The user id and application name are derived from the API subscription 
	* <br/>        and passed in the transaction context.
	* <br/>        Optional Parameters:
	* <br/>        	- uiComponentName. The UI component for which to store the preferences
	* <br/>        	- uiComponentDefault. A boolean indicating whether to upsert the default preferences for the UI component.
	* <br/>        	- appDefault. A boolean indicating whether to upsert the default preferences for the application. Cannot be combined
	* <br/>        	with the UI Component options
	* <br/>        	- globalDefault. A boolean indicating whether to upsert the global preferences. Cannot be combined
	* <br/>        	with either app default or UI Component options
	* <br/>        
	*/
	public upsertUserPreference(request: UpsertUserPreferenceRqst,
							   queryParams: UpsertUserPreferenceQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/users/preferences'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Delete existing preference grouping. 
	* <br/>        Primary Parameter:
	* <br/>        	- groupingInstId. Unique ID for a preference grouping
	* <br/>        
	*/
	public deletePreferenceGrouping(
							   pathParams: DeletePreferenceGroupingPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/{groupingInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get the details about a preference grouping. The user id and application name are derived from the API subscription and passed in the transaction context.
	* <br/>        Primary Parameter:
	* <br/>        	- groupingInstId. Unique ID for a preference grouping.
	* <br/>        
	*/
	public getPreferenceGrouping(
							   pathParams: GetPreferenceGroupingPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetPreferenceGroupingResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/{groupingInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List all preference groupings. The user id and application name are derived from the API subscription and passed in the transaction context.
	* <br/>        Optional Parameters:
	* <br/>        	- uiComponentName. The UI component for which to retrieve the preferences
	* <br/>        	- listInfo. Filter and pagination criteria.
	* <br/>        
	*/
	public listAllPreferenceGroupings(
							   queryParams: ListAllPreferenceGroupingsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListAllPreferenceGroupingsResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/all'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List all preference grouping details that are assigned to a user. 
	* <br/>        Optional Parameters:
	* <br/>        	- uiComponentName. The UI component for which to retrieve the preferences
	* <br/>        	- listInfo. Filter and pagination criteria.
	* <br/>        Primary Parameter:
	* <br/>           	- userId. Id of the user for which to retrieve the preference groupings.
	* <br/>        
	*/
	public listPreferenceGroupingsForUser(
							   pathParams: ListPreferenceGroupingsForUserPath,
							   queryParams: ListPreferenceGroupingsForUserQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListPreferenceGroupingsForUserResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/users/{userId}/preference-groupings'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List user preferences for preference grouping by grouping id.
	* <br/>        Primary Parameter:
	* <br/>        	- groupingInstId. Unique ID for a customer grouping.
	* <br/>        
	*/
	public listUserPreferencesForPreferenceGrouping(
							   pathParams: ListUserPreferencesForPreferenceGroupingPath,
							   queryParams: ListUserPreferencesForPreferenceGroupingQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListUserPreferencesForPreferenceGroupingResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/{groupingInstId}/preferences'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List all users that are attached to a preference grouping
	* <br/>        Primary Parameter:
	* <br/>        	- groupingInstId. Unique ID for a preference grouping
	* <br/>        
	*/
	public listUsersForPreferenceGrouping(
							   pathParams: ListUsersForPreferenceGroupingPath,
							   queryParams: ListUsersForPreferenceGroupingQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListUsersForPreferenceGroupingResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/{groupingInstId}/users'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation upserts a preference grouping that contains its own preference criteria stored as a clob.  The user ID and application name are derived from the API subscription and passed in the transaction context.
	* <br/>
	* <br/>
	* <br/>Primary Parameters:
	* <br/>- groupingName
	* <br/>- groupingPreference 
	* <br/>        
	* <br/>Optional Parameter:
	* <br/>- uiComponentName   
	*/
	public upsertPreferenceGrouping(request: UpsertPreferenceGroupingRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<UpsertPreferenceGroupingResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation upserts a User Preference and attaches it to an existing Preference grouping that contains its own preference criteria stored as a clob.  The user ID and application name are derived from the API subscription and passed in the transaction context.
	* <br/>
	* <br/>Primary Parameters:
	* <br/>- uiComponentName: the UI component for which to create the preferences.
	* <br/>- groupingInstId: the unique ID of the preference group to be attached to the user preference.
	* <br/>- userPreference: the User Preference object to be created.
	*/
	public upsertUserPreferenceForPreferenceGrouping(request: UpsertUserPreferenceForPreferenceGroupingRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<UpsertUserPreferenceForPreferenceGroupingResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/preferences'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation deletes an existing Preference Grouping Tool. 
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid groupingId and toolPreferredSequenceNbr must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the Preference Grouping Tool is deleted.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deletePreferenceGroupingTool(
							   pathParams: DeletePreferenceGroupingToolPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/{groupingId}/tool-preferences/{toolPreferenceSequenceNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation gets the details about a Preference Grouping Tool. The user ID and application name are derived from the API subscription and passed in the transaction context.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. 
	* <br/>
	* <br/>Post-conditions:
	* <br/>1.  
	*/
	public getPreferenceGroupingTool(
							   pathParams: GetPreferenceGroupingToolPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetPreferenceGroupingToolResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/{groupingId}/tool-preferences/{toolPreferenceSequenceNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists preference groupings. The user ID and application name are derived from the API subscription and passed in the transaction context.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid groupName must be provided.
	* <br/>2. Optional filter parameters:
	* <br/>- uiComponentName
	* <br/>- filterKey1
	* <br/>- filterKey2
	* <br/>- filterKey3
	* <br/>- filterKey4
	* <br/>- filterKey5
	* <br/>- listInfo
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the preference grouping tools that match the input criteria will be listed.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listPreferenceGroupingTools(
							   pathParams: ListPreferenceGroupingToolsPath,
							   queryParams: ListPreferenceGroupingToolsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListPreferenceGroupingToolsResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/{groupingName}/tool-preferences'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation upserts a preference grouping tool that contains its own preference criteria stored as a clob.  The user ID and application name are derived from the API subscription and passed in the transaction context.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The input fields provided must be valid. 
	* <br/>
	* <br/>Post-conditions:
	* <br/>Post-condition:
	* <br/>1. If the upsert is successful, then either the entry will be updated if it already exists or if it doesn't, then it will be created.
	* <br/>2. Otherwise an appropriate error message will be returned. 
	*/
	public upsertPreferenceGroupingTool(request: UpsertPreferenceGroupingToolRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<UpsertPreferenceGroupingToolResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-grouping-tools'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Attach an existing preference grouping to the user. 
	* <br/>        
	*/
	public createPreferenceGroupingUser(request: CreatePreferenceGroupingUserRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-groupings/users'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete a user from an existing preference grouping. 
	* <br/>        Primary Parameter:
	* <br/>        	- groupingInstId. Unique ID for a preference grouping
	* <br/>        	- userId. ID for for the user to be removed from the preference grouping
	* <br/>        
	*/
	public deletePreferenceGroupingUser(
							   pathParams: DeletePreferenceGroupingUserPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/preference-grouping/{groupingInstId}/users/{userId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(UserPreferenceApiService.UserPreferenceApiEndpoint);
	}
}
