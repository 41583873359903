
export enum CustomerNoteForCd {
	ACCOUNT = 'Account',
	ALIAS = 'Alias',
	CONTACT = 'Contact',
	FUNCTION_NOTE = 'FunctionNote',
	FUNCTION_REMARK = 'FunctionRemark',
	INVOICE = 'Invoice',
	REQUEST = 'Request'}

