import {DataOptions} from './data-options';
import {HttpOptions} from './http-options';
import {ApiRequest} from './api-request';
import {FileContents} from '@xpo-ltl/sdk-common';

export abstract class BaseService {
  private _converters: Array<{ type: Array<string>, convert: Function }> = [];

  constructor() {
    // this._converters.push( { type: ['string'], convert: (value) => value });
  }

  hasConverter(value: any): Function {
    const items = this._converters.find((item) => {
      return (item.type.some(x => x === (typeof value)));
    });
    return items ? items.convert : undefined;
  }

  isPrimitive(test: any): boolean {
    return (test !== Object(test));
  }

  populateQueryParams(value: any, params?: any): Object {
    if (!params) {
      params = {};
    }
    const addValue = (item, key) => {
      if (!params[key]) {
        params[key] = [];
      }
      params[key].push(item);
    };

    Object.keys(value).forEach(key => {
      if (value[key] !== undefined) {
        if (this.isPrimitive(value[key])) {
          addValue(value[key], key);
        } else if (value[key] instanceof Date) {
          addValue(value[key].getTime(), key);
        } else if (Array.isArray(value[key])) {
          value[key].forEach(item => {
            this.populateQueryParams({[key]: item}, params);
          });
        } else {
          const converter = this.hasConverter(value[key]);
          if (converter) {
            addValue(converter(value[key]), key);
          } else {
            this.populateQueryParams(value[key], params);
          }
        }
      }
    });
    return params;
  }

  /**
   * Currently only supports simple objects.  That is objects the are singularly nested.  replacementValue must be
   * of type Object.
   */
  populatePathParams(basePath: string, replacementValue: any): string {
    let modifiedPath = basePath;
    if (!!replacementValue) {
      Object.keys(replacementValue).forEach(key => {

        if (replacementValue[key] !== undefined && replacementValue[key] !== null) {


          if (this.isPrimitive(replacementValue[key])) {
            modifiedPath = modifiedPath.replace(`{${key}}`, replacementValue[key]);
          } else if (replacementValue[key] instanceof Date) {
            modifiedPath = modifiedPath.replace(`{${key}}`, replacementValue[key].getTime());
          } else if (Array.isArray(replacementValue[key])) {
            modifiedPath = modifiedPath.replace(`{${key}}`, replacementValue[key].join(','));
          } else {
            const converter = this.hasConverter(replacementValue[key]);
            if (converter) {
              modifiedPath = modifiedPath.replace(`{${key}}`, converter(replacementValue[key]));
            } else {
              modifiedPath = modifiedPath.replace(`{${key}}`, replacementValue[key]);
            }
          }
        }
      });
    }
    return modifiedPath;
  }

  generateApiRequest(url: string,
                     data: DataParams,
                     dataOptions: DataOptions,
                     httpOptions?: HttpOptions): ApiRequest {
    const apiRequest = new ApiRequest();
    apiRequest.dataOptions = dataOptions || ApiRequest.defaultDataOptions;
    if (httpOptions) {
      apiRequest.httpOptions = httpOptions;
    }
    if (data && data.body) {
      apiRequest.body = data.body;
    } else if (data && data.formData) {
      apiRequest.body = this.generateFormData(data.formData);
    }
    if (data && data.queryParams) {
      apiRequest.queryParams = this.populateQueryParams(data.queryParams);
    }
    if (data && data.pathParams) {
      apiRequest.serviceRootUri = this.populatePathParams(url, data.pathParams);
    } else {
      apiRequest.serviceRootUri = url;
    }
    return apiRequest;
  }

  protected getUrl(uri) {
    let url = this.getEndPoint();
    if (!url.endsWith('/') && !uri.startsWith('/')) {
      url += '/';
    } else if (url.endsWith('/') && uri.startsWith('/')) {
      url = url.substr(0, url.length - 1);
    }
    return `${url}${uri}`;
  }

  protected abstract getEndPoint(): string;

  private generateFormData(formDataRqst: any): FormData {
    return Object.keys(formDataRqst).reduce((formData, key) => {
      const currInput = formDataRqst[key];
      if (currInput) {
        if (currInput instanceof Array && currInput.length > 0 && currInput[0] instanceof FileContents) {
          (currInput as Array<FileContents>).forEach(file => {
            const blob = new Blob([file.contents.buffer]);
            formData.append(key, blob, file.fileName);
          });
        } else if (currInput instanceof FileContents) {
          const blob = new Blob([currInput.contents.buffer]);
          formData.append(key, blob, currInput.fileName);
        } else {
          formData.append(key, currInput);
        }
      }
      return formData;
    }, new FormData());
  }
}

export interface DataParams {
  queryParams?: any;
  pathParams?: any;
  body?: any;
  formData?: any;
}
