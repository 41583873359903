import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	CreateAppMetadataRqst,
	GetAppMetadataResp,
	GetAppMetadataPath,
	ListApplicationsResp,
	PerformAppGlobalSearchResp,
	PerformAppGlobalSearchQuery,
	PerformAppGlobalSearchPath,
	UpdateAppMetadataRqst
} from './api-appmetadata';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class AppMetadataApiService extends BaseService {
	private static AppMetadataApiEndpoint = 'appmetadataApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation creates a new entry in the App Metadata table.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid values which will be used to create the app must be provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If successful, then a new application record will be created in the table.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public createAppMetadata(request: CreateAppMetadataRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/apps'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation retrieves the metadata for an app.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The name of the app is required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, the minimum app version and list of list of supported browsers are returned.
	* <br/>2. Otherwise an appropriate eror message is returned.
	*/
	public getAppMetadata(
							   pathParams: GetAppMetadataPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetAppMetadataResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/apps/{appName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists the applications that exist.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the list of applications including details such as name, supported browsers, minimum app version, etc. is returned.
	* <br/>2. Otherwise an appropriate eror message is returned.
	* <br/>
	*/
	public listApplications(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListApplicationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/applications'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Performs an app global search and returns results for Shipment, Invoice, Claims, and/or Disputes.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. keyValueEntered is provided and valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the value provided is valid and found, then results for Shipment, Invoice, Claims, and/or Disputes.
	*/
	public performAppGlobalSearch(
							   pathParams: PerformAppGlobalSearchPath,
							   queryParams: PerformAppGlobalSearchQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<PerformAppGlobalSearchResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/app-global-search/{searchTxt}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation updates an existing entry in the App Metadata table.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid values which will be used to update the app must be provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If successful, then the application record will be updated in the table.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public updateAppMetadata(request: UpdateAppMetadataRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/apps'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(AppMetadataApiService.AppMetadataApiEndpoint);
	}
}
