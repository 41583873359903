
export enum DeliveryQualifierReasonCd {
	ATTEMPT_CONSIGNEE_RELATED = 'AttemptConsigneeRelated',
	ATTEMPT_SHIPPER_RELATED = 'AttemptShipperRelated',
	BROKER_CLOSED = 'BrokerClosed',
	CASH_NOT_AVAILABLE = 'CashNotAvailable',
	CLIENT_CLEAR = 'ClientClear',
	CLIENT_CREDIT = 'ClientCredit',
	CLOSED_FOR_HOLIDAY = 'ClosedForHoliday',
	CLOSED_HOLIDAY = 'ClosedHoliday',
	CLOSED_INVENTORY = 'ClosedInventory',
	COD_REQUIRED = 'CodRequired',
	CREDIT_HOLD = 'CreditHold',
	CUSTOMER_REQUESTED_CONSOLIDATION = 'CustomerRequestedConsolidation',
	CUSTOMER_REQUEST_FUTURE_DELIVERY = 'CustomerRequestFutureDelivery',
	CUSTOMER_SHUTDOWN = 'CustomerShutdown',
	CUSTOMER_STRIKE = 'CustomerStrike',
	CUSTOMS_IMPORT_EXPORT = 'CustomsImportExport',
	DOCK_PICKUP_REQUIRED = 'DockPickupRequired',
	DRIVER_RELATED = 'DriverRelated',
	FAILED_PARS = 'FailedPars',
	FINAL_CONSIGNEE_RELATED = 'FinalConsignee-related',
	FINAL_SHIPPER_RELATED = 'FinalShipper-related',
	GOVERNMENT_DOCS_REQUIRED = 'GovernmentDocsRequired',
	HELD_FOR_PROTECTIVE_SERVICE = 'HeldForProtectiveService',
	IMPROPER_UNLOAD_EQUIPMENT = 'ImproperUnloadEquipment',
	INCORRECT_BROKER = 'IncorrectBroker',
	INFO_MISSING = 'InfoMissing',
	INSUFFICIENT_DELIVERY_TIME = 'InsufficientDeliveryTime',
	INSUFFICIENT_TIME_TO_COMPLETE_DELIVERY = 'InsufficientTimeToCompleteDelivery',
	INT_NON_CARRIER_DELIVERY = 'IntNonCarrierDelivery',
	LIFTGATE_REQUIRED_FOR_DELIVERY = 'LiftgateRequiredForDelivery',
	MECHANICAL_BREAKDOWN = 'MechanicalBreakdown',
	MISSED_DELIVERY = 'MissedDelivery',
	NO_BROKER = 'NoBroker',
	OTHER = 'Other',
	OTHER_CARRIER_RELATED_REASON = 'OtherCarrier-relatedReason',
	PACKAGE_MIS_SORTED = 'PackageMis-sorted',
	PAPERWORK_ISSUE = 'PaperworkIssue',
	PREVIOUS_STOP_CAUSED_DELAY = 'PreviousStopCausedDelay',
	RECEIVING_TIMES_RESTRICTED = 'ReceivingTimesRestricted',
	RECIPIENT_UNAVAILABLE = 'RecipientUnavailable',
	RESIDENTIAL_NOT_HOME = 'ResidentialNotHome',
	RETURN_SHIPPER_RELATED = 'ReturnShipperRelated',
	WAITING_INSPECTION = 'WaitingInspection',
	WRONG_PRO = 'WrongPro',
	XPO_RELATED = 'XPO-related'}

