
export enum PickupShipmentStatusCd {
	ASSIGNED = 'Assigned',
	CANCELLED = 'Cancelled',
	CONFIRM_ON_BOARD = 'ConfirmOnBoard',
	DELETED = 'Deleted',
	DISPATCHED = 'Dispatched',
	MERGED = 'Merged',
	NEW = 'New'}

