/**
 * AppMetadata API
 * Version: 1.0
 * Build: 1.0.1.6
 */

import {
	ListInfo,
	AuditInfo,
	LatLong,
	GeoCoordinates,
	ActionCd,
	GlobalSearchDetailCd,
	MovementStatusCd,
	RouteCategoryCd,
	RouteStatusCd,
	ShipmentPnDStatusCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to create a new application.
 */
export class CreateAppMetadataRqst {
	/**
	 * Information that will be used to create a new application record.
	 * Required: true
	 * Array: false
	 */
	application:Application = undefined;
}
/**
 * Path parameter used to retrieve app metadata.
 */
export class GetAppMetadataPath {
	/**
	 * Required: true
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Contains information about an app such as minimum version, supported browsers and version etc.
 */
export class GetAppMetadataResp {
	/**
	 * The name of the app
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
	/**
	 * The minimum supported app version
	 * Required: false
	 * Array: false
	 */
	minAppVersion:string = undefined;
	/**
	 * List of supported browsers
	 * Required: false
	 * Array: true
	 */
	supportedBrowsers:Browser[] = undefined;
}
/**
 * List of applications
 */
export class ListApplicationsResp {
	/**
	 * The list of applications that exist.
	 * Required: false
	 * Array: true
	 */
	applications:Application[] = undefined;
}
/**
 * Path parameter for the request to perform an app global search.
 */
export class PerformAppGlobalSearchPath {
	/**
	 * PRO number, Claims number or Disputes number are the expected key values for the first release.
	 * Required: true
	 * Array: false
	 */
	searchTxt:string = undefined;
}
/**
 * Query parameters for the request to perform an app global search.
 */
export class PerformAppGlobalSearchQuery {
	/**
	 * The list of values which allow consumers to optionally indicate the type of data to be included in the response.
	 * Required: false
	 * Array: true
	 */
	globalSearchDetailCds:GlobalSearchDetailCd[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to perform an app global search.
 */
export class PerformAppGlobalSearchResp {
	/**
	 * A list of global search results returned for the key value entered.
	 * Required: false
	 * Array: false
	 */
	globalSearchResult:GlobalSearchResult = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to update a new application.
 */
export class UpdateAppMetadataRqst {
	/**
	 * Information that will be used to update an existing application record.
	 * Required: true
	 * Array: false
	 */
	application:Application = undefined;
}
/**
 * Details about invoice search results.
 */
export class ClaimSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	filingDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimantName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Details about invoice search results.
 */
export class DisputeSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	disputeId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputeType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputeStatus:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimantName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	inspectorName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	debtorName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	workflowItemInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Details about DMS documents search results.
 */
export class DocumentSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	docArchiveTimestamp:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Details about a loaded trailer.
 */
export class GlobalSearchResult {
	/**
	 * Search results relating to Shipment.
	 * Required: false
	 * Array: true
	 */
	shipmentSearchResults:ShipmentSearchResult[] = undefined;
	/**
	 * Search results relating to Invoice.
	 * Required: false
	 * Array: true
	 */
	invoiceSearchResults:InvoiceSearchResult[] = undefined;
	/**
	 * Search results relating to Claims.
	 * Required: false
	 * Array: true
	 */
	claimSearchResults:ClaimSearchResult[] = undefined;
	/**
	 * Search results relating to Disputes.
	 * Required: false
	 * Array: true
	 */
	disputeSearchResults:DisputeSearchResult[] = undefined;
	/**
	 * Search results relating to DMS documents.
	 * Required: false
	 * Array: true
	 */
	documentSearchResults:DocumentSearchResult[] = undefined;
	/**
	 * Search results relating to PnD.
	 * Required: false
	 * Array: true
	 */
	pndSearchResults:PnDSearchResult[] = undefined;
}
/**
 * Details about invoice search results.
 */
export class InvoiceSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Details about PND search results.
 */
export class PnDSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeDate:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	statusCd:RouteStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	categoryCd:RouteCategoryCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPnDStatusCd:ShipmentPnDStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee:InterfaceAcct = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Details about shipment search results.
 */
export class ShipmentSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPnDStatusCd:ShipmentPnDStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hostDestSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billInd:boolean = undefined;
}
/**
 * List of Applications for LTL (added 4/8/2019)
 */
export class Application {
	/**
	 * A unique id for the application
	 * Required: false
	 * Array: false
	 */
	applInstId:number = undefined;
	/**
	 * Name of the application
	 * Required: false
	 * Array: false
	 */
	applName:string = undefined;
	/**
	 * Path to the application, not including the 
	 * 
	 * host
	 * Required: false
	 * Array: false
	 */
	url:string = undefined;
	/**
	 * The minimum supported 
	 * 
	 * version of the application.
	 * Required: false
	 * Array: false
	 */
	minimumVersionNbr:string = undefined;
	/**
	 * An image icon for the application
	 * Required: false
	 * Array: false
	 */
	icon:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	browser:Browser[] = undefined;
}
/**
 * List of browsers for LTL (added 4/8/2019)
 */
export class Browser {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	browserInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	browserName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	versionNbr:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Supported browsers by application (added 
 * 
 * 4/8/2019)
 */
export class SupportedBrowser {
	/**
	 * A unique id for the 
	 * 
	 * application
	 * Required: false
	 * Array: false
	 */
	applInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	browserInstId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class InterfaceAcct {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentAcctInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nationalAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditStatusCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationTypeCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressLine1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressLine2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deletedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastCreatedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastCreatedDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoCoordinates:LatLong = undefined;
	/**
	 * Geo coordinations
	 * Required: false
	 * Array: false
	 */
	geoCoordinatesGeo:GeoCoordinates = undefined;
}
/**
 * Holds a snapshot of InterfaceAcct. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class InterfaceAcctSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:InterfaceAcct = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:InterfaceAcct = undefined;
}



