
export enum JobSelectionCd {
	AM_FLEXIBLE = 'AMFlexible',
	ANYTIME_FLEXIBLE = 'ANYTIMEFlexible',
	CITY = 'City',
	DAY_LINEHAUL = 'DayLinehaul',
	DOCK = 'Dock',
	NIGHT_LINEHAUL = 'NightLinehaul',
	PM_FLEXIBLE = 'PMFlexible'}

