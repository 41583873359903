
export enum EnsembleListenerModeCd {
	AQ_LISTENER = 'AQ_LISTENER',
	AQ_WAIT_LISTENER = 'AQ_WAIT_LISTENER',
	CHANNEL_LISTENER = 'CHANNEL_LISTENER',
	EMAIL_LISTENER = 'EMAIL_LISTENER',
	HUB = 'HUB',
	JMS_LISTENER = 'JMS_LISTENER',
	LISTENER = 'LISTENER',
	LOCKABLE_AQ_LISTENER = 'LOCKABLE_AQ_LISTENER',
	LOCKABLE_CHANNEL_LISTENER = 'LOCKABLE_CHANNEL_LISTENER',
	LOCKABLE_JMS_LISTENER = 'LOCKABLE_JMS_LISTENER',
	LOCKABLE_PUBSUB_LISTENER = 'LOCKABLE_PUBSUB_LISTENER',
	PUBSUB_FILE_LISTENER = 'PUBSUB_FILE_LISTENER',
	PUBSUB_LISTENER = 'PUBSUB_LISTENER',
	PUBSUB_RETRIGGER_LISTENER = 'PUBSUB_RETRIGGER_LISTENER',
	TIMER_LISTENER = 'TIMER_LISTENER'}

