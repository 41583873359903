
export enum CustomerLineStatusCd {
	APPROVED = 'Approved',
	CREDIT_REVIEWED = 'CreditReviewed',
	HELD = 'Held',
	NOT_IN_PROCESS = 'NotInProcess',
	NO_UPDATE = 'NoUpdate',
	PENDING_CREDIT = 'PendingCredit',
	REJECTED = 'Rejected',
	SALES_APPROVED = 'SalesApproved',
	SALES_PENDING = 'SalesPending'}

