
export enum ExternalShipmentStatusCd {
	ARRIVED_AT_DELIVERY = 'ArrivedAtDelivery',
	ARRIVED_AT_PICKUP = 'ArrivedAtPickup',
	CANCELLED = 'Cancelled',
	COMPLETED = 'Completed',
	CONFIRM_ON_BOARD = 'ConfirmOnBoard',
	DRIVER_ALLOCATED = 'DriverAllocated',
	ENROUTE_TO_DELIVERY = 'EnrouteToDelivery',
	ENROUTE_TO_PICKUP = 'EnrouteToPickup',
	FINDING_DRIVER = 'FindingDriver',
	RETURNED = 'Returned',
	TRANSFER = 'Transfer',
	UNALLOCATED = 'Unallocated'}

