
export enum CarrierTenderStatusCd {
	ACCEPTED = 'Accepted',
	ACCEPTED_WITH_EXCEPTION = 'AcceptedWithException',
	ACKNOWLEDGED = 'Acknowledged',
	REJECTED = 'Rejected',
	ROUTED = 'Routed',
	TENDERED = 'Tendered',
	TENDER_FAILED = 'TenderFailed',
	WITHDRAWN = 'Withdrawn'}

